import motrainLogoSrc from '../assets/motrain.svg';
import incentliLogoSrc from '../assets/incentli.svg';
import incentliNegativeLogoSrc from '../assets/incentli-negative.svg';

type Flavour = 'incentli' | 'motrain';

export const flavour: Flavour = (process.env.REACT_APP_FLAVOUR as Flavour | undefined) || 'incentli';

export let brandName: string;
if (flavour === 'motrain') {
  brandName = 'Motrain';
} else {
  brandName = 'Incentli';
}

export let brandUrl: string;
if (flavour === 'motrain') {
  brandUrl = 'https://motrain.com';
} else {
  brandUrl = 'https://incentli.com';
}

export let logoSrc: string;
if (flavour === 'motrain') {
  logoSrc = motrainLogoSrc;
} else {
  logoSrc = incentliLogoSrc;
}

export let darkBgLogoSrc: string;
if (flavour === 'motrain') {
  darkBgLogoSrc = motrainLogoSrc;
} else {
  darkBgLogoSrc = incentliNegativeLogoSrc;
}

export let knowledgeBaseUrl: string;
if (flavour === 'motrain') {
  knowledgeBaseUrl = 'https://help.motrainapp.com';
} else {
  knowledgeBaseUrl = 'https://help.incentli.com';
}

export let levelsTemplateUrl: string = 'https://motrainstorage.blob.core.windows.net/public/levels/{level}.svg';

export let supportEmail: string;
if (flavour === 'motrain') {
  supportEmail = 'support@motrain.com';
} else {
  supportEmail = 'support@incentli.com';
}

export let flavourHasCompletionTransationFilters = true;
export let flavourHasEventTransactionFilters = false;
